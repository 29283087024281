.card {
    width: 350px;
    height: 300px;
    perspective: 1000px;
    position: relative;
    transition: transform 0.8s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
  }
  
  .card:hover .card-inner {
    transform: rotateY(180deg);
    /* border: 1px solid #22D3EE; */
  }
  
  .card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
 
  .card-front,
  .card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    
    backface-visibility: hidden;
  }
  .card-front{
    top: 20%;
  }
  .card-back {
    border-radius: 10px;
    border: 3px solid #22D3EE;
    transform: rotateY(180deg);
  }
  
  .card img {
  margin: auto;
width: 30%;
  }
  
  .card h2 {
    margin-top: 20px;
    text-align: center;
  }
  
  .card p {
    padding: 0 20px;
    text-align: center;
  }
/* Media query for laptops */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .card {
    width: 270px;
    max-height: 300px;
    /* overflow-y: scroll; */
    perspective: 1000px;
    position: relative;
    transition: transform 0.8s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
  }
  .card p {
    font-size: 0.8rem;
  }
  .card h2{
    font-size: 1.1rem;
  }
}
