.pricing-card{
    width: 40%;
    display: flex;
   flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pricing-card1{
    width: 70%;
}
.pricing-card2{
    max-width: 30%;
}
@media only screen and (max-width: 767px) {
    .pricing-card{
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .pricing-card{
        width: 100%;
        margin-bottom: 10px;
    }
    .pricing-card1{
        width: 100%;
        text-align: center;
    }
    .pricing-card2{
        max-width: 60%;
        margin-top: 10px;
    }
  }
  