.portfolio-banner{

    background-image: url('../../../Images/banner1.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    font-family: Poppins;
    background-position: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}




@media only screen and (max-width: 767px) {
    /* Your CSS styles for mobile devices here */
    .portfolio-banner{
        height: 60vh;

    }
  }
  