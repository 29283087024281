.quote-bg{
    background-color: #F1F1F1;
}


@media only screen and (max-width: 768px) {
    .wrap-flex{
        /* display: none;   */
        flex-wrap: wrap;
    }
    .btn-responsive{
        width: 12rem;
margin: auto;
    }
  }
  