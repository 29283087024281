.form-Book{
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    padding: 20px 0px;
}
.form-book-bg{
    background-image: url('../../Images/white-paper-texture.avif');
}