.book-cover-card:hover{
  transform: scale(0.9); /* You can adjust the scaling factor as per your preference */

      }

/* Media query for laptops */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .book-cover-card{
width: 30%;
    }
  }
  @media only screen and (max-width: 767px) {
    .book-cover-card{
width: 80%;
min-height: 1rem;
    }
  }
  

  /* Book_Cover.css */

.flip-container {
  perspective: 1000px;
}

.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flipper {
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  position: relative;
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
  z-index: 1;
}
