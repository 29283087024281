@media only screen and (max-width: 768px) {
  .text-form-responsive{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;
    flex-direction: column;
  }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {

    .text-form-responsive{
width: 100%;

    }

    .media-text-res{
      font-size: 2rem;
    }
  }