@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar {
  background-image: url('../../Images/white-paper-texture.avif');
  background-color: #E9E9EB;
    color: black;
    position: fixed;
    width: 100%;
padding: 10px 0px;
  }
  
  /* Existing styles... */
.mb-dropdown{
  display: none;
}
  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    width: max-content;
    left: 0;
    padding: 10px;
    background-color: black;
    z-index: 1;
  }
  .dropdown > li {
    color: white;
    margin-bottom: 14px;
  }
  .nested-dropdown {
    display: none;
    position: absolute;
    width: max-content;
    top: 0;
    left: 100%;
    padding: 20px;
    color: white;
    background-color: black;
    z-index: 2;
  }
  .nested-dropdown > li {
    margin-bottom: 14px;
  }
  .navbar-links > li:hover .dropdown {
    display: block;
  }
  .nested-dropdown-container{
    color: white;
  }
  .nested-dropdown-container:hover .nested-dropdown {
    color: white;
    display: block;
  }
  /* Existing styles... */
  
  .navbar-container {
    display: flex;
    z-index: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .navbar-hamburger {
    display: none; /* Hide by default on larger screens */
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .navbar-links {
    margin-right: 25px;
    list-style: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .navbar-links li {
    position: relative;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: black;
  }
  
  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    z-index: 1;
  }
  
  /* .navbar-links li:hover .dropdown {
    display: block;
  }
   */
  @media (max-width: 768px) {

.with-dropdown{
  display: none;
}
    .navbar-container {
      justify-content: space-between;
    }
    
    .mb-dropdown{
      display: block;
    }


    .navbar-hamburger {
      display: block;
      margin-right: 15px;
    }
  
    .navbar-links {
      display: none;
      z-index: 41;
      flex-direction: column;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
    background-image: url('../../Images/white-paper-texture.avif');
      padding-bottom: 10px; 
    }
  
    .navbar-links.mobile-menu-open {
      display: flex;
    }
  
    .navbar-links li {
      width: 100%;
      text-align: center;
    }
  
    .navbar-links li:hover .dropdown {
      display: none;
    }
  }
  .listitemcolorhover:hover{
    color: #22D3EE;
  }